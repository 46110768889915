import { SignUp } from '@clerk/remix'

export const ClerkLayout = {
	helpPageUrl: 'mailto:support@doreveal.com',
	termsPageUrl: 'https://doreveal.com/terms-of-service',
	privacyPageUrl: 'https://doreveal.com/privacy-policy',
	logoImageUrl: '/static-assets/square-logo.svg',
	logoLinkUrl: '/',
}

export default function SignUpPage() {
	return (
		<>
			<div className="flex max-w-4xl mx-auto mt-20 align-middle w-max gap-12 p-12 bg-[#211a49] rounded-lg">
				<div className="w-full lg:w-1/2 max-w-md">
					<SignUp
						appearance={{
							layout: ClerkLayout,
						}}
					/>
				</div>
				<div>
					<WhyConsiderReveal />
				</div>
			</div>
		</>
	)
}

const WhyConsiderReveal = () => {
	const features = [
		'Privacy-friendly AI.',
		'High quality transcriptions',
		'Multi-lingual support.',
		'Reviews transcripts to find answers to research questions.',
		'Identifies emerging themes.',
		'Tests hypotheses.',
		'Compares all interviews to find similarities and differences.',
		'Finds verbatim quotes.',
		'And more...',
	]

	return (
		<div className="w-full text-white justify-center text-lg">
			<div className="pt-12">
				<h2 className="text-xl font-bold">Why Consider Reveal?</h2>
				<ul className="space-y-2 mb-4 p-3 ml-8">
					{features.map((feature, index) => (
						<li key={index} className="list-disc">
							{feature}
						</li>
					))}
				</ul>
				<p className="mt-8 border-t border-violet-300 border-dotted p-6">
					Your free trial includes analysis of{' '}
					<strong className="font-medium">up to 3 interviews</strong>.
				</p>
			</div>
		</div>
	)
}
